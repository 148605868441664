/** @module helpers/linearGraph/drawDates */

import * as d3 from 'd3';

/**
 * Gets the name of timezone.
 *
 * @param {Object} timezone - The object with a name and offset of time zone.
 * @return {string} Time zone string.
 */
function getTimezoneName(timezone) {
  const timezoneName = !parseInt(timezone.offset) && !timezone.name
    ? 'UTC'
    : timezone.name;

  return timezoneName.length > 0
    ? `${timezoneName}:`
    : timezoneName;
}

/**
 * Draws the dates on the chart.
 *
 * @param {Selection} axisLayer - The axis layer.
 * @param {Array} extentX - min. and max. of the X values.
 * @param {Object} params - Chart parameters.
 * @param {Object} config - Configuration object.
 * @return {string} Dates string.
 */
export default (data, axisLayer, params, config) => {
  const { width, paddings } = params;
  const { fontSize, timezone } = config;
  const extentX = d3.extent(data, (d) => d['created_at']);
  const period = extentX[1].getTime() - extentX[0].getTime(); //ms

  const format = (period >=  86400000) // if period is more than 24 hours
    ? d3.timeFormat('%Y-%m-%d')
    : d3.timeFormat('%Y-%m-%d %H:%M:%S');

  const dates = `${format(extentX[0])} - ${format(extentX[1])}`;

  const timezoneName = getTimezoneName(timezone);

  axisLayer.append('text')
    .attr('class', 'time-period')
    .attr('x', width - paddings)
    .attr('y', paddings)
    .attr('dy', '0.7em')
    .style('font-size', fontSize.title)
    .text(`${timezoneName} ${dates}`)
    .attr('text-anchor', 'end');

  return dates;
};
