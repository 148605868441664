/** @module modules/BuiltInChart/graphSizes */

/**
 * Calculates the chart sizes.
 */
export default (elem, chartsNumber) => {
  const elemRect = elem.getBoundingClientRect();
  const paddings = parseInt(getComputedStyle(elem).padding || 0);

  return {
    width: elemRect.width - 2 * paddings,
    height: (elemRect.height - 2 * paddings) / chartsNumber
  };
};
