/** @module helpers/linearGraph/drawTitle */

/**
 * Draws title.
 *
 * @param {Selection} axisLayer - Chart axes layer.
 * @param {number} paddings - Chart paddings.
 * @param {Object} config - Configuration object.
 * @return {string} The title of the chart.
 */
export default (axisLayer, paddings, config) => {
  const { fontSize, chartTitle } = config;

  if (chartTitle) {
    axisLayer.append('text')
      .attr('class', 'title')
      .attr('x', paddings)
      .attr('y', paddings)
      .attr('dy', '0.7em')
      .style('font-size', fontSize.title)
      .text(chartTitle);
  }

  return chartTitle;
};