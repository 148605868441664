/** @module helpers/linearGraph/drawHorizontalLines */

/**
 * Draws horizontal lines.
 *
 * @param {Selection} axisLayer - Chart axis layer.
 * @param {Object} scale - Scale Y.
 * @param {Object} params - Chart parameters.
 * @return {Array} Array of lines data.
 */
export default (axisLayer, scale, params) => {
  const { width, paddings, distance } = params;

  const lines = scale.ticks(2).map(tick => [0, tick]);

  const filteredLines = lines.filter((line) =>
    line[1] <= scale.domain()[1]
  );

  axisLayer
    .append('g')
    .attr('class', 'horizontal-lines')
    .selectAll('g')
    .data(filteredLines)
    .enter()
    .append('g')
    .attr('class', 'horizontal-lines-item')
    .attr('transform', d => `translate(${ [d[0], scale(d[1])] })`)
    .append('line')
    .attr('x1', distance.y1 + paddings)
    .attr('x2', width - paddings - distance.y2)
    .attr('y1', 0.5)
    .attr('y2', 0.5)
    .style('stroke', '#999');

  return lines;
};
