import { Controller } from 'stimulus';

export default class extends Controller {
  change(event) {
    let url = new URL(window.location.href);
    let param_name = event.target.name;

    if(event.target.checked) {
      url.searchParams.set(param_name, 'true');
    } else {
      url.searchParams.delete(param_name);
    }

    window.location = url;
  }
}
