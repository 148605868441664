import {Controller} from 'stimulus';

export default class extends Controller {
  connect() {
    switch (this.data.get('action')) {
      case 'index':
        this.oldCodeIndex();
        break;
      case 'show':
        this.oldCodeShow();
        break;
    }
  }

  oldCodeIndex() {
    $('.moderation-block').on('click', '.button-group-item', function () {
      $('.submit_button').prop('disabled', false);
      $(this).closest('tr').removeClass('unmoderated');
      $('.not_all_moderated').hide();
    });

    $('form.moderation').on('submit', function (e) {
      var all_checked = true;

      $('#events-table > tbody > tr').each(function () {
        var $this = $(this);
        var is_checked = $this.find("input.event-result").is(':checked');

        if (is_checked !== true) {
          all_checked = false;
          $this.addClass('unmoderated');
        }
      });

      if (all_checked !== true) {
        $('.not_all_moderated').show();

        e.preventDefault();
      }
    });
  }

  oldCodeShow() {

  }
}
