import { Controller } from 'stimulus';
import Choises from 'choices.js';
import rome from '@bevacqua/rome/src/rome';
import moment from 'moment';

export default class extends Controller {
  static targets = ['select', 'date', 'datetime'];

  connect() {
    this.selectTargets.forEach((select, _index) => {
      new Choises(
        select,
        {
          itemSelectText: '',
        }
      );
    });

    rome.use(moment);

    this.dateTargets.forEach((date, _index) => {
      date.autocomplete = 'off';
      rome(
        date,
        {
          inputFormat: 'YYYY.MM.DD',
          time: false,
          weekStart: 1,
        }
      );
    });

    this.datetimeTargets.forEach((date, _index) => {
      date.autocomplete = 'off';
      rome(
        date,
        {
          inputFormat: 'YYYY.MM.DD HH:MM',
          time: true,
          weekStart: 1,
        }
      );
    });
  }
}
