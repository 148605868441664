import {Controller} from 'stimulus';
import {publishFlash} from "../lib/flash";

export default class extends Controller {
  updateRow(event) {
    const [response, status, xhr] = event.detail;

    this.element.outerHTML = response['row'];
    publishFlash(response['flash']);
  }
}
