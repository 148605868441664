import {Controller} from 'stimulus';
import PingTooltip from 'ping-tooltip';

export default class extends Controller {
  connect() {
    this.oldCode();
  }

  oldCode() {
    const pingTooltip = new PingTooltip(
      'file-queue-graph',
      {
        tooltipTitles: {},
        axes: {
          x: {
            format: '%d.%m'
          },
          y: {
            format: {'шт.': 1},
            domain: 'auto'
          }
        },
        padding: 15,
        fontSize: {
          title: 18
        },
        timezone: {
          offset: '+00:00',
          name: 'ЧЛБ'
        },
      }
    );

    this.setGraphHeigth();

    setTimeout((function () {
      this.drawGraph(pingTooltip);
    }).bind(this), 50);

    $('input[name=period]').on('change', (function () {
      this.drawGraph(pingTooltip);
    }).bind(this));
  }

  setFilesQueuesPath() {
    var period = $("input[name=period]:checked").val();
    var path = "/web_api/file_queue_summary" +
      ".json?" +
      "&period=" +
      period +
      '&graph_type=multiLinear';

    $("#file-queue-graph").attr('path-to-data', path);
  }

  drawGraph(ping_tooltip) {
    if ($('#file-queue-graph').children().length !== 0) {
      this.setFilesQueuesPath();
      ping_tooltip.redraw();
    } else {
      ping_tooltip.draw();
    }
  }

  setGraphHeigth() {
    var window_height = $(window).height();
    var menu_height = $('header.navigation').outerHeight();
    var header_height = $('header.header').outerHeight();
    var graph_height = window_height - (menu_height + header_height);
    $('.file-queue-graph').height(graph_height);
  }
}
