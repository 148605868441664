import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['personImage', 'personMainImage', 'personMainImageId', 'personImagesIds', 'personMainImageType'];

  selectPersonMainImage(event) {
    const personMainImage = event.target;
    this.personMainImageTargets.forEach((checkbox, _index) => {
      if(personMainImage !== checkbox) {
        checkbox.checked = false;
      }
    });

    this.personMainImageTypeTarget.value = personMainImage.dataset.type;
    this.personMainImageIdTarget.value = personMainImage.value;
  }

  selectPersonImage(_event) {
    this.personImagesIdsTarget.value = this.personImageTargets.
      filter((image) => image.checked).
      map((image) => image.value).join(',');
  }
}
