import * as d3 from 'd3';

import errorMessages from '../helpers/errorMessages';

/** Class represents a loader of data. */
class DataLoader {
  constructor() {
    this._dataCollection = {};
    this._loadingPaths = [];
    this._pendingRequests = [];
  }

  /**
   * Load data.
   */
  load(paths) {
    return new Promise((resolve, reject) => {
      if (this._arePathsLoaded(paths)) {
        resolve(this.getData(paths));
      } else {
        this._pendingRequests.push({paths, resolve, reject});

        const pathsToLoad = this._getPathsToLoad(paths);
        pathsToLoad.forEach(path => loadPath.bind(this)(path));
      }
    });

    function loadPath(path) {
      this._loadingPaths.push(path);

      d3.json(path, (error, dataItem) => {
        if (!dataItem || error) {
          if (error.currentTarget.status == 0) {
            console.error(errorMessages.connectionRefused(path));
            dataItem = new Error('CONNECTION_REFUSED');
          } else {
            console.error(errorMessages.failedToLoad(path));
            dataItem = new Error('FAILED_TO_LOAD_DATA');
          }
        }

        this._updateDataCollection(path, dataItem);

        const index = this._loadingPaths.indexOf(path);
        if (index > -1) this._loadingPaths.splice(index, 1);

        this._resolve();
      });
    }
  }

  /**
   * Get data for paths.
   */
  getData(paths) {
    const data = {};

    paths.forEach(path => {
      data[path] = this._dataCollection[path];
    });

    return data;
  }

  /**
   * Resolves pending request.
   */
  _resolve() {
    this._pendingRequests.forEach(request => {
      if (this._arePathsLoaded(request.paths)) {
        request.resolve(this.getData(request.paths));
        request.complete = true;
      }
    });

    this._pendingRequests = this._pendingRequests.filter(request => !request.complete);
  }

  stopLoading() {
    this._pendingRequests.forEach(request => {
      request.reject('Data loading stopped due to redraw chart');
      request.complete = true;
    });

    this._pendingRequests = [];
  }

  /**
   * Adds the new paths to the queue of download.
   */
  _getPathsToLoad(paths) {
    const pathsToLoad = [];

    paths.forEach(path => {
      if (!this._dataCollection[path] && this._loadingPaths.indexOf(path) == -1) {
        pathsToLoad.push(path);
      }
    });

    return pathsToLoad;
  }

  /**
   * Checks if the paths are loaded
   */
  _arePathsLoaded(paths) {
    for (let i = 0; i < paths.length; i++) {
      if (!this._dataCollection[paths[i]]) return false;
    }

    return true;
  }

  /**
   * Adds a data item to the data collection.
   */
  _updateDataCollection(path, dataItem) {
    this._dataCollection[path] = dataItem;
  }
}

export default DataLoader;
