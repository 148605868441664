$(document).ready(function() {
  $(".dropdown-button").click(function () {
    var $button, $menu;
    $button = $(this);
    $menu = $button.siblings(".dropdown-select");

    if ($menu.hasClass('show-menu')) {
      $(document).off('click', hide_dropdown_handler);
    } else {
      $('.dropdown-select').removeClass('show-menu');
      $(document).off('click', hide_dropdown_handler);
      $(document).on('click', hide_dropdown_handler);
    }

    $menu.toggleClass("show-menu");
    $menu.children("li:not(.delay-closing)").click(function () {
      setTimeout(function() {
        $menu.removeClass("show-menu");
        $(document).off('click', hide_dropdown_handler);
      }, 100);
    });

    $menu.children("li.delay-closing").click(function () {
      setTimeout(function() {
        $menu.removeClass("show-menu");
        $(document).off('click', hide_dropdown_handler);
      }, 2000);
    });
  });

  function hide_dropdown_handler(e) {
    e.stopPropagation();

    var container = $(".dropdown");

    if (container.has(e.target).length === 0) {
      $('.dropdown-select').removeClass('show-menu');
      $(document).off('click', hide_dropdown_handler);
    }
  }
});

