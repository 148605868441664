/** @module helpers/linearGraph/makeLineGenerators */

import * as d3 from 'd3';

/**
 * Creates line generator.
 *
 * @param {Object} scales - Chart scales.
 * @parma {Object} datasetNames - The names of the data columns.
 * @return {function} Line generator.
 */
export default (scales, datasetNames) => {
  if (!datasetNames)
    datasetNames = {x: 'created_at', y: 'value'};

  return d3.line()
    .defined(d => d[datasetNames.y] !== null)
    .x((d) => scales.x(d[datasetNames.x]))
    .y((d) => scales.y(d[datasetNames.y]));
}
