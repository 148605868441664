import * as d3 from 'd3';

export default (data, tooltipRect, xScale) => {
  const x0 = xScale.invert(d3.mouse(tooltipRect.node())[0]);

  const bisectDate = d3.bisector(
    d => new Date(d['created_at'])
  ).left;

  const nIndex = bisectDate(data, x0) - 1;

  return nIndex > 0 ? nIndex : 0;
};
