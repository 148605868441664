class Scratch {
  constructor(parent) {
    this._parent = parent;
  }

  draw(paddings, config) {
    if (this._scratch) return;

    const y1 = paddings + config.fontSize.title * 1.7;
    const axisYRect = this._parent.select('.axis--y .domain').node().getBoundingClientRect();

    this._scratch = this._parent.select('.chart-layer').
      append('g').
      attr('class', 'scratch').
      style('display', 'none');

    this._scratch.
      append('line').
      attr('x1', 0).
      attr('x2', 0).
      attr('y1', y1).
      attr('y2', y1 + axisYRect.height);
  }

  remove() {
    this._scratch.remove();
  }

  show() {
    this._scratch.style('display', null);
  }

  hide() {
    this._scratch.style('display', 'none');
  }

  translate(translateX) {
    this._scratch.
      attr('transform', `translate(${[translateX, 0]})`);
  }
}

export default Scratch;
