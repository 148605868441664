import * as d3 from 'd3';

import createSvg from '../../helpers/createSvg';
import attributesConverter from '../../helpers/attributesConverter';
import mergeConfig from '../../helpers/mergeConfig';

import ChartManager from '../../modules/ChartManager';
import ScreenMessage from '../../modules/ScreenMessage';

import translateSvg from './translateSvg';
import isCursorOverElem from './isCursorOverElem';
import updateSvgSizes from './updateSvgSizes';

/** Class represents a chart - tooltip. */
class TooltipChart {
  constructor(config) {
    this._config = config;
    this._sizes = {
      width: this._config.common.width,
      height: this._config.common.height
    };
    this._svg = this._createSvg();
    this._chartManager = new ChartManager();
    this._screenMessage = new ScreenMessage();
  }

  /**
   * Handles mouseenter event.
   */
  createForElement(parent, data) {
    const self = this;

    d3.select(parent).on('mouseenter', function () {
      const currentItem = d3.event.target;
      const elemRect = this.getBoundingClientRect();

      self.createChart(d3.event, elemRect, data);
      self._controlCursorPosition(currentItem, elemRect);
    });
  }

  /**
   * Creates charts for one target HTML element.
   */
  createChart (event, elemRect, data) {
    this.remove();

    const paths = Object.keys(data);
    updateSvgSizes(this._svg, this._sizes, paths.length);

    for (let i = 0; i < paths.length; i++) {
      const translate = [0, this._sizes.height * i];
      const dataItem = data[paths[i]];

      if (dataItem.constructor === Error) {
        this._drawError(dataItem, translate, this._config.common, this._svg, this._sizes);
      } else {
        this._drawChart(dataItem, translate, this._svg, this._sizes, event.target);
      }
    }

    translateSvg(this._svg, event, elemRect);
  }

  _createSvg() {
    let svg = d3.select('.ping-tooltip-graph.tooltiped');

    if (svg.size() == 0) {
      svg = createSvg(d3.select('body'), this._sizes, 'tooltiped');
      svg.style('display', 'none');
    }

    return svg;
  }

  _drawError(dataItem, translate, config, svg, sizes) {
    this._chartManager.drawError(null, dataItem, translate, config, svg, sizes);
  }

  /**
   * Draws tooltip chart.
   */
  _drawChart(data, translate, svg, sizes, parent) {
    svg.style('display', 'block');

    for (const k in data) {
      const graphConfig = mergeConfig.intersection(this._config[k], attributesConverter(parent));
      this._chartManager.createChart(k, data[k], translate, graphConfig, svg, sizes);
    }
  }

  /**
   * Controls the position of the mouse pointer.
   */
  _controlCursorPosition(currentItem, elemRect) {
    d3.select('body').on('mousemove', () => {
      const cursor = {
        x: d3.event.clientX,
        y: d3.event.clientY
      };

      if (!isCursorOverElem(cursor, elemRect, this._config.common.tooltipZoneExtension)) {
        d3.select('body').on('mousemove', null);
        currentItem = null;
        this.remove();
      }
    });
  }

  /**
   * Remove chart.
   */
  remove() {
    this._chartManager.removeCharts();
    const svg = d3.selectAll('.ping-tooltip-graph.tooltiped');

    svg.selectAll('.graph').remove();
    this._screenMessage.remove(svg);
    svg.style('display', 'none');
  }
}

export default TooltipChart;
