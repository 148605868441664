import { Controller } from 'stimulus';
import { PingTooltip } from 'ping-tooltip';

export default class extends Controller {
  static targets = [
    'fileQueueGraph',
    'graphPeriod',
  ];

  connect() {
    if(this.hasFileQueueGraphTarget) {
      this.initFileGraph(this.fileQueueGraphTarget);
    }
  }

  activeTab() {
    setTimeout(() => { this.drawGraph() }, 50);
  }

  drawGraph() {
    this.setPath();
    if (this.fileQueueGraphTarget.children.length !== 0) {
      this.fileQueueGraph.redraw();
    } else {
      this.fileQueueGraph.draw();
    }
  }

  setPath() {
    var device_id = this.fileQueueGraphTarget.dataset.deviceId;
    var period = this.getGraphPeriod();
    var path = "/web_api/files_queue_infos" +
      ".json?" +
      "device_id=" +
      device_id +
      "&period=" +
      period +
      '&graph_type=multiLinear';

    this.fileQueueGraphTarget.setAttribute('path-to-data', path);
  }

  getGraphPeriod() {
    return this.graphPeriodTargets.find((el) => el.checked).value;
  }

  initFileGraph(fileQueueGraph) {
    let className = 'files-queues-graph'
    fileQueueGraph.classList.add(className)

    this.fileQueueGraph = new PingTooltip(
      className,
      {
        tooltipTitles: {
        },
        axes: {
          x: {
            format: '%d.%m'
          },
          y: {
            format: {'шт.': 1},
            domain: 'auto'
          }
        },
        padding: 15,
        fontSize: {
          title: 18
        },
        timezone: {
          offset: '+00:00',
          name: 'ЧЛБ'
        },
      }
    );
  }
}
