/** @module modules/TooltipChart/isCursorOverElem */

/**
 * Checks if the mouse pointer is over the target element.
 */
export default (cursor, elemRect, tooltipZoneExtension) => {
  const { top, right, bottom, left } = elemRect;

  return cursor.x < left - tooltipZoneExtension ? false
    : cursor.x > right + tooltipZoneExtension ? false
    : cursor.y > bottom + tooltipZoneExtension ? false
    : cursor.y < top - tooltipZoneExtension ? false
    : true;
};
