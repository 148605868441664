import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    switch (this.data.get('action')) {
      case 'index':
        this.oldCodeIndex();
        break;
      case 'show':
        this.oldCodeShow();
        break;
    }
  }

  oldCodeIndex() {
    $('tr[data-link]').on('click', function (e) {
      window.open($(this).data('link'), '_blank');
    })
  }

  oldCodeShow() {
    $('.clickable-image').on('click', function (e) {
      let selected = $('.images-grid').find('.selected');
      let photo_filename = $(this).data('filename');

      if(selected !== undefined) { selected.removeClass("selected"); }

      $(this).addClass("selected");
      $('#photo_filename').val(photo_filename);
    })

    $('.image-saver').on('ajax:success', finishRequest);

    function finishRequest(event) {
      let data = JSON.parse(event.detail[0]);
      if (data.error) {
        $('.flashes').html(data.content)
      }
      else {
        window.close();
      }
    }

    $(function() {
      $("a.image-full").fancybox();
    });
  }
}
