/** @module helpers/errorMessages */

import capitalizeFirstLetter from '../helpers/capitalizeFirstLetter';

/** Error messages. */
export default {
  /**
   * Creates the messages for errors "Failed to load".
   *
   * @param {string} path - The path to data.
   * @return {string} Error message.
   */
  failedToLoad(path) {
    return `Failed to load data from URL: "${path}".`;
  },

  /**
   * Creates the messages for errors "Data is empty".
   *
   * @param {string} path - The path to data.
   * @param {string} type - The type of the chart.
   * @return {string} Error message.
   */
  isEmpty(path, type) {
    const data = type
      ? `${capitalizeFirstLetter(type)} data`
      : 'Data';

    return `${data} from URL: "${path}" is empty.`;
  },

  /**
   * Creates the messages for errors "Incorrect format".
   *
   * @param {string} path - The path to data.
   * @param {string} type - The type of the chart.
   * @return {string} Error message.
   */
  incorrectFormat(path, type) {
    const data = type
      ? `${capitalizeFirstLetter(type)} data`
      : 'Data';

    return `${data} from URL: "${path}" has an incorrect format.`;
  },

  /**
   * Creates the messages for errors "Connection Refused".
   *
   * @param {string} path - The path to data.
   * @return {string} Error message.
   */
  connectionRefused(path) {
    return `Failed to load data from URL: "${path}".`;
  }
};
