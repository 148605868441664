// // import * as d3 from 'd3';
// // const Spinner = require('spin.js');
import * as d3 from 'd3';
import Spinner from 'spin.js';
import config from './config';
import DataLoader from './modules/DataLoader';
import DataFormatter from './modules/DataFormatter';
import BuiltInChart from './modules/BuiltInChart';
import TooltipChart from './modules/TooltipChart';

import mergeConfig from './helpers/mergeConfig';

import { uniq, flatten } from 'lodash';

/** Class represents a ping tooltip. */
export class PingTooltip {
  constructor(className, options) {
    this._className = className;
    this._config = this._mergeConfig(config, options);
    this._dataLoader = new DataLoader();
    this._dataFormatter = new DataFormatter();
    this._builtInChart = new BuiltInChart(this._config);
    this._tooltipChart = new TooltipChart(this._config);
  }

  /** Draw charts for a group of HTML elements. */
  draw() {
    this.remove();

    this._builtIn = d3.selectAll(`.${this._className}[path-to-data][built-in]`);
    this._tooltiped = d3.selectAll(`.${this._className}[path-to-data]:not([built-in])`);

    if (this._builtIn.size() > 0) {
      this._create(this._builtIn, this._builtInChart, true);
    }

    if (this._tooltiped.size() > 0) {
      this._create(this._tooltiped, this._tooltipChart);
    }
  }

  /** Remove charts. */
  remove() {
    this._dataLoader.stopLoading();

    if (this._builtInChart) this._builtInChart.remove();
    if (this._tooltipChart) this._tooltipChart.remove();

    if (this._builtIn) {
      this._builtIn.selectAll('svg').remove();
      this._builtIn.selectAll('.spinner').remove();
    }

    if (this._tooltiped) this._tooltiped.on('mouseenter', null);
  }

  /** Show charts. */
  show() {
    this._builtIn.selectAll('svg').style('display', 'block');
    this._builtIn.selectAll('.spinner').style('display', 'block');
    this._tooltiped.selectAll('svg').style('display', 'block');
  }

  /** Hide charts. */
  hide() {
    this._builtIn.selectAll('svg').style('display', 'none');
    this._builtIn.selectAll('.spinner').style('display', 'none');
    this._tooltiped.selectAll('svg').style('display', 'none');
  }

  /** Redraw charts. */
  redraw() {
    this.remove();
    this.draw();
  }

  /**
   * Get spinner.
   * */
  _getSpinner(elem) {
    const options = getOptions(elem, this._config.common.spinner);
    return new Spinner(options);

    function getOptions(elem, config) {
      const sizes = {
        radius: elem.clientHeight / 6,
        length: elem.clientHeight / 6
      };

      return Object.assign({}, config, sizes);
    }
  }

  /**
   * Create charts of the specified type.
   */
  _create(elems, chartGroup, useSpinners) {
    elems.nodes().forEach(elem => {
      if (useSpinners) {
        var spinner = this._getSpinner(elem);
        spinner.spin(elem);
      }

      const paths = this._getElemPath(elem);
      const loaded = this._dataLoader.load(paths);

      loaded.
        then(
          data => {
            if (spinner) spinner.stop();

            const formattedData = this._dataFormatter.format(data, this._config);
            chartGroup.createForElement(elem, formattedData);
          },
          error => {
            console.error(error);
          }
        );
    });
  }

  /**
   * Gets the paths to data from the html attributes.
   */
  _getPaths(items) {
    const paths = [];

    for (let i = 0; i < items.size(); i++) {
      const item = items.nodes()[i];
      paths.push(this._getElemPath(item));
    }

    return uniq(flatten(paths));
  }

  _getElemPath(elem) {
    const paths = d3.select(elem).attr('path-to-data').split(',');

    return paths.map(path => path.trim());
  }

  /**
   * Merges default configuration with options's object.
   */
  _mergeConfig(config, options) {
    const common = mergeConfig.intersection(config.common, options);
    const linear = mergeConfig.intersection(config.linear, options);
    const doubleLinear = mergeConfig.intersection(config.doubleLinear, options);
    const multiLinear = mergeConfig.intersection(config.multiLinear, options);
    const binary = mergeConfig.intersection(config.binary, options);

    return {
      common,
      linear: mergeConfig.union(common, linear),
      doubleLinear: mergeConfig.union(common, doubleLinear),
      multiLinear: mergeConfig.union(common, multiLinear),
      binary: mergeConfig.union(common, binary)
    };
  }
}

export default PingTooltip;
