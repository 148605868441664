import { getCookie, setCookie } from '../lib/cookies'

$(function() {
  $(".sidebar-minimizer").on('click', function() {
    var $body = $('body');

    $body.toggleClass('sidebar-minimized');
    var $minimized = $body.hasClass('sidebar-minimized');
    setCookie('sidebar-minimized', ($minimized ? '1' : '0'), 31);
  });

  var $sidebar_minimized = getCookie('sidebar-minimized') === '1';

  if($sidebar_minimized) {
    $('body').addClass('sidebar-minimized');
  }
});
