import * as d3 from 'd3';
import getParams from '../../helpers/linearGraph/getParams';
import Scale from '../../helpers/linearGraph/Scale';

/** Class represents a binary chart. */
class Binary {
  constructor(svg, translate, config) {
    this._chart = svg.select('g.graph');
    this._config = config;
    this._chartLayer = this._chart.select('g.chart-layer');
  }

  /**
   * Draws the binary chart.
   */
  draw(data, sizes, linearChartParams) {
    const sequencesRanges = this._getSequencesRanges(data);

    sequencesRanges.forEach(range => {
      this._drawBinaryBlock(data, range, sizes, linearChartParams);
    });
  }

  remove() {}

  /**
   * Gets the ranges of the sequences.
   */
  _getSequencesRanges(data) {
    const sequences = this._getSequences(data);
    const seqRanges = this._getRanges(sequences);

    return seqRanges;
  }

  /**
   * Gets the sequences.
   */
  _getSequences(data) {
    const sequences = [];
    let sequence = [];

    data.forEach((d) => {
      if (d['value'] == true) {
        sequence.push(d);
      } else {
        if (sequence.length > 1)
          sequences.push(sequence);

        sequence = [];
      }
    });

    if (sequence.length > 1) sequences.push(sequence);

    return sequences;
  }

  /**
   * Gets the ranges of sequences.
   */
  _getRanges(sequences) {
    const ranges = sequences.map(
      (sequence) => d3.extent(sequence, (sItem) => sItem['created_at'])
    );

    return ranges;
  }

  /**
   * Draws binary block.
   */
  _drawBinaryBlock(data, range, sizes, linearChartParams) {
    if (!this._chart.node()) return;

    const params = linearChartParams || getParams(sizes, this._config, [0]);
    const scaleX = new Scale(data, params).x();

    const x = (range[0] >= scaleX.domain()[0])
      ? scaleX(range[0])
      : scaleX(scaleX.domain()[0]);

    const y = params.paddings + this._config.fontSize.title * 1.7;
    const axisYRect = this._chart.select('.axis--y .domain').node().getBoundingClientRect();
    const width = scaleX(range[1]) - x;

    const {color, opacity} = this._config.binaryBlocks;
    this._chartLayer.insert('rect', ':first-child')
      .attr('class', 'binary-rect')
      .attr('x', x)
      .attr('y', y)
      .attr('width', width)
      .attr('height', axisYRect.height)
      .style('fill', color)
      .style('fill-opacity', opacity);
  }
}

export default Binary;
