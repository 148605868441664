import * as d3 from 'd3';

import Tooltip from '../../modules/Tooltip/index';
import Scale from '../../helpers/linearGraph/Scale';
import Axis from '../../helpers/linearGraph/Axis';
import getParams from '../../helpers/linearGraph/getParams';
import makeLineGenerators from '../../helpers/linearGraph/makeLineGenerators';
import drawAxes from '../../helpers/linearGraph/drawAxes';
import drawLine from '../../helpers/linearGraph/drawLine';
import drawTitle from '../../helpers/linearGraph/drawTitle';
import drawDates from '../../helpers/linearGraph/drawDates';
import getDataIndex from '../../helpers/linearGraph/getDataIndex';
import createChartElement from '../../helpers/linearGraph/createChartElement';
import longestValue from '../../helpers/longestValue';
import longestSign from '../../helpers/longestSign';
import drawHorizontalLines from '../../helpers/linearGraph/drawHorizontalLines';

/** Class represents a double linear chart. */
class DoubleLinear {
  constructor(svg, translate, config) {
    this._chart = createChartElement(svg, translate);
    this._config = config;
    this._tooltip = new Tooltip(this._chart, this._config);
    this._colors = this._config.colors.slice(0, 2);
  }

  /**
   * Draws the double linear chart.
   */
  draw(data, sizes) {
    this._data = data;
    this._params = this._getChartParams(sizes, this._config, data);
    this._scales = this._makeScales(this._data, this._params, this._config);
    this._axes = this._makeAxes(this._config.axes, this._scales);
    this._axisLayer = drawAxes(this._chart, this._axes, this._params, this._config);

    this._drawLines(this._data, this._chart, this._colors, this._scales);
    drawDates(this._data, this._axisLayer, this._params, this._config);
    this._colorizeText(this._axisLayer, this._colors);
    drawTitle(this._axisLayer, this._params.paddings, this._config);
    drawHorizontalLines(this._axisLayer, this._scales.y1, this._params);
  }

  remove() {
    if (this._tooltip.tooltipArea) {
      this._tooltip.remove();
    }
  }

  connectTooltip() {
    const { paddings } = this._params;
    const formattedValue = {
      'y1': this._axes['y1'].formattedValue,
      'y2': this._axes['y2'].formattedValue,
    };

    this._tooltip.draw(this._colors, paddings, this._params);

    this._tooltip.tooltipArea.
      on('mouseover', () => {
        this._tooltip.show();
      }).
      on('mouseout', () => {
        this._tooltip.hide();
      }).
      on('mousemove', () => {
        const dIndex = getDataIndex(this._data, this._tooltip.tooltipArea, this._scales.x);
        const dItem = this._data[dIndex];

        const translateX = this._scales.x(dItem['created_at']);
        const focusesData = getFocusesData(dItem, this._colors, this._scales, translateX);
        const tableItems = getTableItems(dItem, this._colors, this._config, formattedValue);

        this._tooltip.update(dItem, focusesData, tableItems, translateX);
      });

    function getFocusesData(dItem, colors, scales, translateX) {
      return [1, 2].map(i => {
        return {
          color: colors[i - 1],
          translate: [
            translateX,
            scales['y' + i](dItem['value' + i])
          ]
        }
      })
    }

    function getTableItems(dItem, colors, config, formattedValue) {
      return [1, 2].map(i => {
        return {
          title: config.tooltipTitles['value' + i],
          text: formattedValue['y' + i] ? formattedValue['y' + i](dItem['value' + i]) : dItem['value' + i],
          color: colors[i - 1]
        }
      })
    }
  }

  _getChartParams(sizes, config, data) {
    const signLength = [
      getSignLength(config.axes.y1, data, 'value1'),
      getSignLength(config.axes.y2, data, 'value2')
    ];

    function getSignLength(axisConfig, data, datasetName) {
      const valueLength = longestValue(data, datasetName, axisConfig.domain);
      const formatLength = longestSign(axisConfig.format);

      return valueLength + formatLength;
    }

    return getParams(sizes, config, signLength);
  }

  /**
   * Makes the scales of the chart.
   */
  _makeScales(data, params, config) {
    const scale = new Scale(data, params);

    return {
      x: scale.x(),
      y1: scale.y('value1', config.axes.y1.domain, config.fontSize),
      y2: scale.y('value2', config.axes.y2.domain, config.fontSize)
    };
  }

  /**
   * Makes the axes of the chart.
   */
  _makeAxes(axesConfig, scales) {
    const axis = [
      new Axis({
        x: axesConfig.x.format,
        y: axesConfig.y1.format
      }),
      new Axis({
        x: axesConfig.x.format,
        y: axesConfig.y2.format
      })
    ];

    return {
      x: axis[0].x(scales.x),
      y1: axis[0].y(scales.y1),
      y2: axis[1].y(scales.y2, 'right')
    };
  }

  _drawLines(data, chart, colors, scales) {
    const lines = [
      makeLineGenerator(scales, 'y1', 'value1'),
      makeLineGenerator(scales, 'y2', 'value2')
    ];

    drawLine(chart, data, lines[0], colors[0]);
    drawLine(chart, data, lines[1], colors[1]);

    function makeLineGenerator(scales, yScaleName, yDatasetName) {
      return makeLineGenerators(
        {x: scales.x, y: scales[yScaleName]},
        {x: 'created_at', y: yDatasetName}
      );
    }
  }

  /**
   * Colorizes the text of the axes.
   */
  _colorizeText(axisLayer, colors) {
    const axesNodes = axisLayer.selectAll('.axis--y').nodes();

    d3.select(axesNodes[0]).
      selectAll('text').
      attr('fill', colors[0]);

    d3.select(axesNodes[1]).
      selectAll('text').
      attr('fill', colors[1]);
  }
}

export default DoubleLinear;
