import * as d3 from 'd3';

export default (data, datasetName, yDomain) => {
  if (yDomain == 'auto') yDomain = ['auto', 'auto'];

  const domain = [];

  domain[0] = yDomain[0] == 'auto'
    ? d3.min(data, (d) => d[datasetName])
    : parseInt(yDomain[0]) || 0;

  domain[1] = yDomain[1] == 'auto'
    ? d3.max(data, (d) => d[datasetName])
    : parseInt(yDomain[1]) || d3.max(data, (d) => d[datasetName]);

  return Math.max(domain[0].toString().length, domain[1].toString().length);
}
