/** @module helpers/mergeConfig */

import { merge } from 'lodash';

export default {
  /**
   * Updates the values of existing configuration parameters.
   *
   * @param {Object} config - Configuration object.
   * @param {Object} options - Options.
   * @return {Object} Merged configuration object.
   */
  intersection(config, options) {
    if (!options) return config;

    function merge(config, options) {
      if (typeof config !== typeof options) return config;

      let mergedConfig = {};

      for (const k in config) {
        if (config[k].constructor === Object && options[k])
          mergedConfig[k] = merge(config[k], options[k]);
        else
          mergedConfig[k] = k in options ? options[k] : config[k];
      }

      //use options item if config item is an empty object
      if (config.constructor === Object && Object.keys(config).length == 0)
        mergedConfig = options;

      return mergedConfig;
    }

    return merge(config, options);
  },

  /**
   * Merges the configuration with options.
   *
   * @param {Object} config - Configuration object.
   * @param {Object} options - Options.
   * @return {Object} Merged configuration object.
   */
  union(config, options) {
    return merge({}, config, options);
  }
};