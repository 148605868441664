/** @module config */

/** Common configuration. */
const common = {
  width: 400,
  height: 150,
  fontSize: {
    title: 10,
    graph: 10,
    legend: 12
  },
  padding: 10,
  tooltipZoneExtension: 5,
  graphTypes: {
    linear: 'linear',
    binary: 'binary',
    doubleLinear: 'doubleLinear',
    multiLinear: 'multiLinear'
  },
  spinner: {
    position: 'relative',
    color: '#444444'
  },
  timezone: {
    name: '',
    offset: ''
  },
  tooltipTitles: {},
  chartTitle: '',
  screenMessages: {
    dataIsEmpty: 'Нет данных',
    connectionRefused: 'Ошибка связи, попробуйте обновить страницу'
  },
  intervals: {
    handle: true,
    time: 60 * 60 * 1000 //ms. (1 hour)
  },
  colors: [
    '#ff0000',
    '#4879ff',
    '#3ee03e',
    '#ffbe04',
    '#e377c2',
    '#66cdff',
    '#2ca02c',
    '#8c564b',
    '#e377c2',
    '#ff9896',
    '#9edae5',
    '#7f7f7f',
    '#9467bd',
    '#bcbd22',
    '#f7b6d2',
    '#d62728',
    '#1deeff',
    '#ff7f0e',
    '#98df8a',
    '#c49c94'
  ],
  axes: {
    x: {
      format: '%H:%M'
    }
  }
};

/** Linear chart configuration. */
const linear = {
  datasetNames: {
    created_at: 'created_at',
    value: 'value'
  },
  axes: {
    y: {
      format: {},
      domain: [0, 100]
    }
  }
};

/** Double linear chart configuration. */
const doubleLinear = {
  datasetNames: {
    created_at: 'created_at',
    value1: 'value1',
    value2: 'value2'
  },
  axes: {
    y1: {
      format: {},
      domain: [0, 'auto']
    },
    y2: {
      format: {},
      domain: [0, 'auto']
    }
  }
};

const multiLinear = {
  datasetNames: {
    created_at: 'created_at',
    value: 'value'
  },
  axes: {
    y: {
      format: {},
      domain: [0, 100]
    }
  }
};

/** Binary chart configuration. */
const binary = {
  datasetNames: {
    created_at: 'created_at',
    value: 'value'
  },
  binaryBlocks: {
    color: '#ffc8a7',
    opacity: 0.5
  }
};

/**
 * Exports full configuration.
 */
export default {common, linear, doubleLinear, multiLinear, binary};
