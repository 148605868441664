import { findKey } from 'lodash';
import errorMessages from '../../helpers/errorMessages';
import ChartDataFormatter from './ChartDataFormatter';

/** Class represents a formatter of data. */
class DataFormatter {
  constructor() {
    this._chartDataFormatter = new ChartDataFormatter();
  }

  /**
   * Formats data.
   */
  format(data, config) {
    const formattedData = {};

    for (const path in data) {
      formattedData[path] = this._formatDataByPath(data[path], path, config);
    }

    this._handleEmptyIntervals(formattedData, config.common.intervals);

    return formattedData;
  }

  _formatDataByPath(dataByPath, path, config) {
    if (dataByPath.constructor === Error) {
      return dataByPath;
    } else {
      const formattedStructure = this._formatStructure(dataByPath, config.common.graphTypes, path);

      return this._formatItems(formattedStructure, config, path);
    }
  }

  /**
   * Formats data structure.
   */
  _formatStructure(data, graphTypes, path) {
    const formattedData = {};

    data.forEach((d) => {
      const key = findKey(graphTypes, (graphType) => graphType == d.type);

      if (key)
        formattedData[key] = d.data;
      else {
        console.error(errorMessages.incorrectFormat(path));
      }
    });

    return formattedData;
  }

  /**
   * Formats data items.
   */
  _formatItems(data, config, path) {
    const formattedData = {};

    for (const chart in data) {
      formattedData[chart] = this._chartDataFormatter.format(data[chart], chart, path, config);
    }

    return formattedData;
  }

  /**
   * Checks if data item has correct format.
   */
  _checkDataItemFormat(properties, config) {
    const datasetNames = config.datasetNames;
    const props = properties;

    for (const k in datasetNames) {
      if (!props[datasetNames[k]]) return false;
    }

    return true;
  }

  /**
   * Handle empty data intervals.
   */
  _handleEmptyIntervals(data, intervals) {
    if (!intervals.handle) return;

    for (const k in data) {
      if (data[k].linear && data[k].linear.constructor == Array)
        this._handleEmptyInterval(data[k].linear, 'linear', intervals.time);

      if (data[k].doubleLinear && data[k].doubleLinear.constructor == Array)
        this._handleEmptyInterval(data[k].doubleLinear, 'doubleLinear', intervals.time);
    }
  }

  /**
   * Handle empty data interval.
   */
  _handleEmptyInterval(data, chartType, time) {
    data.forEach((d, i) => {
      const k = i > 0 ? i - 1 : 0;
      const interval = data[i]['created_at'] - data[k]['created_at'];

      if (interval > time) {
        const newDataItem = {
          created_at: new Date(data[i]['created_at'].getTime() - 1)
        };

        if (chartType == 'linear') {
          newDataItem.value = data[k]['value'];
        } else if (chartType == 'doubleLinear') {
          newDataItem.value1 = data[k]['value1'];
          newDataItem.value2 = data[k]['value2'];
        }

        data.splice(i, 0, newDataItem);
      }
    });
  }
}

export default DataFormatter;
