import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    switch (this.data.get('action')) {
      case 'index':
        this.oldCodeIndex();
        break;
      case 'show':
        this.oldCodeShow();
        break;
    }
  }

  oldCodeIndex() {

  }

  oldCodeShow() {

  }
}
