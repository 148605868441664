import {Controller} from 'stimulus';
import Choices from 'choices.js';

export default class extends Controller {
  static targets = ['flashes', 'roles'];

  connect() {
    if (this.hasRolesTarget) {
      this.rolesTargets.forEach(function (role) {
        new Choices(role, {
          classNames: {
            containerOuter: 'choices roles-input',
          }
        });
      });
    }

    this.oldCode();
  }

  saveRole(event) {
    let form = $(event.target).closest('form')[0];
    Rails.fire(form, 'submit');
  }

  oldCode() {
    $('.users .roles-form').each(function (index, form) {
      var $form = $(form);

      $form.on('ajax:error', function (event) {
        var detail = event.detail;
        var response = detail[0];
        var $input = $(event.target).find('.roles-input').find('.choices__inner');

        $input.addClass('error');

        if (typeof (response) !== 'undefined' && typeof (response.message) !== 'undefined') {
          $('.flashes').html("<div class='flash--error'>" + response.message + "</div>")
        } else {
          $('.flashes').html("<div class='flash--error'>" + detail[1] + "</div>")
        }
      });

      $form.on('ajax:success', function (event) {
        var detail = event.detail;
        var response = detail[0];
        var $input = $(event.target).find('.roles-input').find('.choices__inner');
        if (typeof (response) !== 'undefined') {
          if (response['status'] === 'ok') {
            $('.flashes').html('');
            $input.removeClass('error');
            $input.addClass('saved');
            setTimeout(function () {
              $input.removeClass('saved');
            }, 200);
          } else {
            $('.flashes').html("<div class='flash--error'>" + response['message'] + "</div>");
            $input.addClass('error');
          }
        }
      });
    });
  }
}
