/** @module modules/TooltipChart/translateSvg */

/**
 * Sets the offset of the target svg element.
 */
export default (svg, event, elemRect) => {
  const svgRect = svg.node().getBoundingClientRect();

  const position = {
    right: event.clientX + svgRect.width,
    left: event.clientX - svgRect.width,
    top: (elemRect.top + elemRect.height / 2) - svgRect.height,
    bottom: (elemRect.top + elemRect.height / 2) + svgRect.height
  };

  //define horizontal position
  const left = position.right <= window.innerWidth ? event.clientX
    : position.left > 0 ? event.clientX - svgRect.width
    : svgRect.width < window.innerWidth ? (window.innerWidth - svgRect.width) / 2
    : event.clientX;


  //define vertical position
  const top = position.bottom <= window.innerHeight ? elemRect.top + elemRect.height / 2
    : position.top > 0 ? (elemRect.top + elemRect.height / 2) - svgRect.height
    : svgRect.height < window.innerHeight ? (window.innerHeight - svgRect.height) / 2
    : elemRect.top + elemRect.height / 2;

  svg
    .style('left', `${left + window.pageXOffset}px`)
    .style('top', `${top + window.pageYOffset}px`);
};
