/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import Rails from "@rails/ujs"
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

import "../stylesheets/application"

import "jquery"
let moment = require('moment')
require('moment/locale/ru')
global.moment = moment;
require("jquery.fancybox.pack")

import '../components/accordion_tabs.js'
import '../components/dropdown.js'
import '../components/fancybox.js'
import '../components/header_menu.js'
import '../components/lonely_submitter.js'
import '../components/sidebar.js'
import '../components/table.js'
import '../controllers'

Rails.start()
