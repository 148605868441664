import EventsController from '../events_controller';

export default class extends EventsController {
  static targets = ['personImage', 'personMainImage', 'personMainImageId', 'personImagesIds', 'personMainImageType'];

  connect() {
    switch (this.data.get('action')) {
      case 'show':
        this.oldCodeShow();
        break;
    }
  }

  selectPersonMainImage(event) {
    const personMainImage = event.target;
    this.personMainImageTargets.forEach((checkbox, _index) => {
      if(personMainImage !== checkbox) {
        checkbox.checked = false;
      }
    });

    this.personMainImageTypeTarget.value = personMainImage.dataset.type;
    this.personMainImageIdTarget.value = personMainImage.value;
  }

  selectPersonImage(_event) {
    this.personImagesIdsTarget.value = this.personImageTargets.
    filter((image) => image.checked).
    map((image) => image.value).join(',');
  }

  oldCodeShow() {
    $('.all-face-images').on('click', '.small-image-wrap', function () {
      select_face_image($(this), 'all-face-full-images');
    });

    $('.all-face-full-images').on('click', '.small-image-wrap', function () {
      select_face_image($(this), 'all-face-images');
    });

    function select_face_image($this, slave_class) {
      if ($('.change-face-image').length === 0){
        return;
      }

      var datetime = $this.data('datetime');

      $('.small-image-wrap').removeClass('selected-image');
      $this.addClass('selected-image');

      $('.' + slave_class + ' .small-image-wrap.datetime-' + datetime).addClass('selected-image');
    }
  }
}
