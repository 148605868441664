/** @module helpers/linearGraph/drawLine */

/**
 * Draws path.
 *
 * @param {Selection} graph - Chart element.
 * @param {Array} data - Array of data.
 * @param {function} line - Line generator.
 * @param {string} color - Color, HEX.
 * @return {Selection} Chart layer.
 */
export default (graph, data, line, color) => {
  let chartLayer = graph.select('.chart-layer');

  if (chartLayer.size() == 0) {
    chartLayer = graph.append('g')
      .attr('class', 'chart-layer');
  }

  chartLayer.append('path')
    .attr('class', 'line')
    .attr('d', line(data))
    .style('stroke', color);

  return chartLayer;
};