/** @module helpers/attributesConverter */

import * as d3 from 'd3';

import config from '../config';

var KEBAB_REGEX = /[A-Z\u00C0-\u00D6\u00D8-\u00DE]/g;

function kebabCase(str) {
  return str.replace(KEBAB_REGEX, function (match) {
    return '-' + match.toLowerCase();
  });
}

/**
 * Receives the attributes that match the configuration parameters.
 * Converts the format of the received attributes.
 *
 * @param {Selection} elem - HTML element.
 * @return {Array} attrs - Array of attributes in converted format.
 */
function getAttributes(elem) {
  const attrs = [];
  const keys = Object.keys(config.common);

  keys.forEach((key) => {
    const attr = elem.attr(kebabCase(key));

    if (attr != null)
      attrs.push({ key, val: attr });
  });

  return attrs;
}

/**
 * Check if the string is JSON.
 *
 * @param {string} str - Any string.
 * @return {boolean}
 */
function isJson(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

/**
 * Parses value if it's a JSON.
 *
 * @param {string} str - The value of the attribute.
 * @return {(string|Object)} The value or parsed value.
 */
function parseJsonValue(str) {
  return isJson(str)
    ? JSON.parse(str)
    : str;
}

/**
 * Creates configuration object from HTML attributes.
 *
 * @param {Selection} elem - HTML element.
 * @return {Object} Configuration object.
 */
export default (elem) => {
  if (!elem.node) elem = d3.select(elem);

  const config = {};
  const attrs = getAttributes(elem);

  attrs.forEach((attr) => {
    config[ attr.key ] = attr.val
      ? parseJsonValue(attr.val)
      : true;
  });

  return config;
};
