import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['eventsIds', 'eventId'];

  updateEventsIds(event) {
    if(this.hasEventsIdsTarget) {
      let eventIds = this.eventIdTargets.filter(field => field.checked).map(field => field.value).join(',');
      this.eventsIdsTargets.forEach(field => field.value = eventIds);
    }
  }
}
