import * as d3 from 'd3';

/** Class represents a scale. */
class Scale {
  /**
   * Constructor.
   *
   * @constructor
   * @param {Array} data - Array of data.
   * @param {Object} params - Chart parameters.
   */
  constructor(data, params) {
    this._data = data;
    this._width = params.width;
    this._height = params.height;
    this._paddings = params.paddings;
    this._distance = params.distance;
  }

  /**
   * Creates scale X.
   */
  x() {
    const domain = this._getXDomain();
    const range = this._getXRange();

    const scaleX = d3.scaleTime()
      .range(range)
      .domain(domain);

    return scaleX;
  }

  /**
   * Creates scale Y.
   *
   * @param {string} datasetName - The name of data column.
   * @param {number|string} yDomain - The domain settings.
   * @param {Object} fontSize - The text sizes of the chart and title.
   * @return {function} The scale Y function.
   */
  y(datasetName, yDomain, fontSize) {
    const domain = this._getYDomain(datasetName, yDomain);
    const range = this._getYRange(fontSize);

    const scaleY = d3.scaleLinear()
      .range(range)
      .domain(domain);

    return scaleY;
  }

  /**
   * Gets min. and max. of X values.
   *
   * @private
   * @return {Array} Min. and max. of X values.
   */
  _getXDomain() {
    return d3.extent(this._data, (d) => d['created_at']);
  }

  /**
   * Calculates scale X range.
   *
   * @private
   * @return {Array} The range of the scale X.
   */
  _getXRange() {
    return [
      this._distance.y1 + this._paddings,
      this._width - this._paddings - this._distance.y2
    ];
  }

  /**
   * Calculates the scale Y domain.
   *
   * @private
   * @param {string} datasetName - The name of data column.
   * @param {number|string} yDomain - The domain settings.
   * @return {Array} The domain of the scale Y.
   */
  _getYDomain(datasetName, yDomain) {
    if (yDomain == 'auto') yDomain = ['auto', 'auto'];

    const domain = [];

    domain[0] = yDomain[0] == 'auto'
      ? d3.min(this._data, (d) => d[datasetName])
      : parseInt(yDomain[0]) || 0;

    domain[1] = yDomain[1] == 'auto'
      ? d3.max(this._data, (d) => d[datasetName])
      : parseInt(yDomain[1]) || d3.max(this._data, (d) => d[datasetName]);

    return domain;
  }

  /**
   * Calculates scale Y range.
   *
   * @private
   * @param {Object} fontSize - The text sizes of the chart and title.
   * @return {Array} The range of the scale Y.
   */
  _getYRange(fontSize) {
    return [
      this._height - this._paddings - this._distance.x,
      fontSize.title * 1.7 + this._paddings
    ];
  }
}

export default Scale;
