import { Controller } from 'stimulus';
import Choises from "choices.js";

export default class extends Controller {
  static targets = [
    'flashes',
    'newEmployee',
    'newPosition',
    'employeesTable'
  ];

  connect() {
    this.initNewEmployee();
  }

  activated() {
    this.loadEmployeesTable();
  }

  initNewEmployee() {
    this.newEmployee = new Choises(
      this.newEmployeeTarget,
      {
        itemSelectText: '',
      }
    );

    new Choises(
      this.newPositionTarget,
      {
        itemSelectText: '',
      }
    );
  }

  loadEmployeesTable() {
    fetch(this.data.get('url')).
      then(response => response.text()).
      then(html => { this.employeesTableTarget.innerHTML = html })
  }

  renderTable(event) {
    let xhr;
    [_, _, xhr] = event.detail;
    this.employeesTableTarget.innerHTML = xhr.responseText;
  }

  newEmployeeSuccess(event) {
    let xhr;
    [_, _, xhr] = event.detail;
    this.employeesTableTarget.innerHTML = xhr.responseText
    this.removeCurrentEmployeeOption();
    $('.flashes').html('');
  }

  newEmployeeError(event) {
    let status;
    [_, status, _] = event.detail;
    if(status === "Conflict") {
      this.removeCurrentEmployeeOption();
    }
  }

  removeCurrentEmployeeOption() {
    let employee = this.newEmployee;
    let currentValue = employee.getValue(true);
    let choices = employee._store.choices.filter(el => { return el.value !== currentValue});
    employee.removeActiveItems();
    employee.setChoices(choices, 'value', 'label', true);
  }
}
