import Tooltip from '../../modules/Tooltip/index';
import Scale from '../../helpers/linearGraph/Scale';
import Axis from '../../helpers/linearGraph/Axis';
import getParams from '../../helpers/linearGraph/getParams';
import makeLineGenerators from '../../helpers/linearGraph/makeLineGenerators';
import drawAxes from '../../helpers/linearGraph/drawAxes';
import drawLine from '../../helpers/linearGraph/drawLine';
import drawTitle from '../../helpers/linearGraph/drawTitle';
import drawDates from '../../helpers/linearGraph/drawDates';
import drawHorizontalLines from '../../helpers/linearGraph/drawHorizontalLines';
import getDataIndex from '../../helpers/linearGraph/getDataIndex';
import createChartElement from '../../helpers/linearGraph/createChartElement';
import longestValue from '../../helpers/longestValue';
import longestSign from '../../helpers/longestSign';

/** Class represents a linear chart. */
class Linear {
  constructor(svg, translate, config) {
    this._chart = createChartElement(svg, translate);
    this._config = config;
    this._color = this._config.colors[0];
    this._tooltip = new Tooltip(this._chart, this._config);
  }

  /**
   * Draws the linear chart.
   */
  draw(data, sizes) {
    this._data = data;
    this._params = this._getChartParams(sizes, this._config, data);
    this._scales = this._makeScales(data, this._params, this._config);
    this._axes = this._makeAxes(this._config.axes, this._scales);
    this._axisLayer = drawAxes(this._chart, this._axes, this._params, this._config);

    this._drawLines(this._data, this._chart, this._color, this._scales);
    drawDates(this._data, this._axisLayer, this._params, this._config);
    drawTitle(this._axisLayer, this._params.paddings, this._config);
    drawHorizontalLines(this._axisLayer, this._scales.y, this._params);

    return this._params;
  }

  remove() {
    if (this._tooltip.tooltipArea) {
      this._tooltip.remove();
    }
  }

  connectTooltip() {
    const { paddings } = this._params;
    const { formattedValue } = this._axes.y;

    this._tooltip.draw([this._color], paddings, this._params);

    this._tooltip.tooltipArea.
      on('mouseover', () => {
        this._tooltip.show();
      }).
      on('mouseout', () => {
        this._tooltip.hide();
      }).
      on('mousemove', () => {
        const dIndex = getDataIndex(this._data, this._tooltip.tooltipArea, this._scales.x);
        const dItem = this._data[dIndex];
        const translateX = this._scales.x(dItem['created_at']);
        const focusesData = [{
          color: this._color,
          translate: [
            translateX,
            this._scales.y(dItem['value'])
          ]
        }];

        const tableItems = [
          {
            title: this._config.tooltipTitles['value'],
            text: formattedValue ? formattedValue(dItem['value']) : dItem['value'],
            color: this._color
          }
        ];

        this._tooltip.update(dItem, focusesData, tableItems, translateX);
      });
  }

  _getChartParams(sizes, config, data) {
    const valueLength = longestValue(data, 'value', config.axes.y.domain);
    const formatLength = longestSign(config.axes.y.format);

    return getParams(sizes, config, [valueLength + formatLength]);
  }

  /**
   * Makes the scales of the chart.
   */
  _makeScales(data, params, config) {
    const scale = new Scale(data, params);

    return {
      x: scale.x(),
      y: scale.y('value', config.axes.y.domain, config.fontSize)
    };
  }

  /**
   * Makes the axes of the chart.
   */
  _makeAxes(axesConfig, scales) {
    const axis = new Axis({
      x: axesConfig.x.format,
      y: axesConfig.y.format
    });

    return {
      x: axis.x(scales.x),
      y: axis.y(scales.y)
    };
  }

  _drawLines(data, chart, color, scales) {
    const lineGenerator = makeLineGenerators(
      scales,
      {x: 'created_at', y: 'value'}
    );

    drawLine(chart, data, lineGenerator, color)
  }
}

export default Linear;
