import { findKey, some } from 'lodash';
import errorMessages from '../../helpers/errorMessages';
import offsetTimezone from '../../helpers/offsetTimezone';

class ChartDataFormatter {
  format(data, chart, path, config) {
    if (chart == 'linear' || chart == 'binary' || chart == 'doubleLinear') {
      const formattedItems = this._formatChartData(data, chart, path, config[chart]);

      return this._offsetTimezone(formattedItems, config.common.timezone);
    } else if (chart == 'multiLinear') {
      const formattedItems = this._formatMultilineChartData(data, chart, path, config[chart]);

      if (formattedItems.constructor !== Error) {
        return formattedItems.map(formattedItem => ({
          title: formattedItem.title,
          data: this._offsetTimezone(formattedItem.data, config.common.timezone)
        }));
      } else {
        return formattedItems;
      }
    }
  }

  _formatChartData(data, chart, path, chartConfig) {
    const { dProperties, dKeys } = this._getItemProperties(data, chartConfig);

    if (data.length === 0) {
      console.error(errorMessages.isEmpty(path, chart));
      return  new Error('DATA_IS_EMPTY');
    } else if (!checkDataItemFormat(dProperties, chartConfig['datasetNames'])) {
      console.error(errorMessages.incorrectFormat(path, chart));
      return new Error('DATA_ITEM_INCORRECT_FORMAT');
    } else {
      return this._formatItem(data, dKeys, dProperties);
    }

    function checkDataItemFormat(properties, datasetNames) {
      for (const k in datasetNames) {
        if (!properties[datasetNames[k]]) return false;
      }

      return true;
    }
  }

  _formatMultilineChartData(data, chart, path, chartConfig) {
    if ((data.length === 0) || (some(data, dataLine => dataLine.data.length === 0 ))) {
      console.error(errorMessages.isEmpty(path, chart));
      return  new Error('DATA_IS_EMPTY');
    }

    const { dProperties, dKeys } = this._getItemProperties(data[0].data, chartConfig);

    if (!checkDataItemFormat(data, dProperties, chartConfig['datasetNames'])) {
      console.error(errorMessages.incorrectFormat(path, chart));
      return new Error('DATA_ITEM_INCORRECT_FORMAT');
    } else {
      return data.map(chart => ({
        title: chart.title,
        data: this._formatItem(chart.data, dKeys, dProperties)
      }));
    }

    function checkDataItemFormat(data, properties, datasetNames) {
      for (let i = 0; i < data.length; i++) {
        const subchartData = data[i];

        if (!subchartData.title || !subchartData.data) {
          return false;
        }
      }

      for (const k in datasetNames) {
        if (!properties[datasetNames[k]]) return false;
      }

      return true;
    }
  }

  _getItemProperties(data, config) {
    const dProperties = {};
    const dKeys = [];

    for (const k in data[0]) {
      const key = findKey(config.datasetNames, datasetName => datasetName == k);

      if (key) {
        dProperties[k] = key;
        dKeys.push(k);
      }
    }

    return {dProperties, dKeys};
  }

  _formatItem(data, keys, properties) {
    return data.map(formatting).sort(sorting);

    function formatting(dItem) {
      const fixedDataItem = {};

      keys.forEach(
        key => fixedDataItem[properties[key]] = dItem[key]
      );

      fixedDataItem['created_at'] = new Date(fixedDataItem['created_at'].split('.')[0]);

      return fixedDataItem;
    }

    function sorting(a, b) {
      return a['created_at'] - b['created_at'];
    }
  }

  /**
   * Offset data time zone.
   */
  _offsetTimezone(data, timezone) {
    if (data.constructor !== Error) {
      return offsetTimezone(data, timezone);
    } else {
      return data;
    }
  }
}

export default ChartDataFormatter;
