import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = ['slave'];

  toggle(event) {
    const target = event.target;
    this.slaveTargets.forEach(checkbox => checkbox.checked = target.checked);
  }
}
