import * as d3 from 'd3';

class Table {
  constructor() {
    this._distance = 10;
    this._windowDistance = 15;
  }

  draw() {
    if (this._table) return;

    this._table = d3.select('body').
      append('div').
      attr('class', 'tooltip-table').
      style('display', 'none');
  }

  remove() {
    this._table.remove();
  }

  show() {
    this._table.style('display', null);
  }

  hide() {
    this._table.style('display', 'none');
  }

  translate() {
    const tableRect = this._table.node().getBoundingClientRect();
    const translate = {
      x: d3.event.clientX + this._distance,
      y: d3.event.clientY + this._distance
    };

    if (translate.x + tableRect.width + this._windowDistance >= window.innerWidth) {
      translate.x = d3.event.clientX - this._distance - tableRect.width;
    }

    if (translate.y + tableRect.height + this._windowDistance >= window.innerHeight) {
      translate.y = d3.event.clientY - this._distance - tableRect.height;
    }

    this._table.style('left', `${translate.x + window.pageXOffset}px`);
    this._table.style('top', `${translate.y + window.pageYOffset}px`);
  }

  update(dataItem, tableItems) {
    this._table.html('');
    const tableElem = this._createTable(dataItem);

    this._fillTable(tableElem, tableItems);
  }

  _createTable(dataItem) {
    const table = this._table.append('table');

    table
      .append('tr')
      .append('td')
      .attr('colspan', '3')
      .text(() => (
        d3.timeFormat('%Y-%m-%d %H:%M:%S')(dataItem['created_at'])
      ));

    return table;
  }

  _fillTable(table, items) {
    items.forEach((item) => {
      const tr = table.append('tr');

      tr.append('td')
        .append('div')
        .style('background-color', item.color);

      tr.append('td')
        .text(item.title);

      tr.append('td')
        .attr('class', 'yValTooltip')
        .text(item.text);
    });
  }
}

export default Table;
