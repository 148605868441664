import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    switch (this.data.get('action')) {
      case 'index':
        this.oldCodeIndex();
        break;
      case 'show':
        this.oldCodeShow();
        break;
    }
  }

  oldCodeIndex() {
    $('tr[data-link]').on('click', function (e) {
      window.open($(this).data('link'), '_blank');
    })
  }

  oldCodeShow() {
    $('.clickable-image').on('click', function(e) {
      if($(this).hasClass('selected')) {
        $(this).removeClass('selected');
      }
      else {
        $(this).addClass('selected');
      }
      $('#image_ids').val(form_image_ids_array().sort(sortNumber).join(','));
    })

    function form_image_ids_array(){
      var image_ids = [];

      $('.selected').each(function(){
        image_ids.push($(this).data('image-id'))
      })

      return image_ids;
    }

    function sortNumber(a,b) {
      return a - b;
    }

    $(function() {
      $("a.image-full").fancybox();
    });

    $('.image-saver').on('ajax:success', closeTab);

    function closeTab(event) {
      let data = JSON.parse(event.detail[0]);
      if(data.error) {
        $('.flashes').html(data.content);
      }
      else {
        window.close();
      }
    }
  }
}
