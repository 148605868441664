import Tooltip from '../../modules/Tooltip/index';
import Scale from '../../helpers/linearGraph/Scale';
import Axis from '../../helpers/linearGraph/Axis';
import getParams from '../../helpers/linearGraph/getParams';
import makeLineGenerators from '../../helpers/linearGraph/makeLineGenerators';
import drawAxes from '../../helpers/linearGraph/drawAxes';
import drawLine from '../../helpers/linearGraph/drawLine';
import drawTitle from '../../helpers/linearGraph/drawTitle';
import drawDates from '../../helpers/linearGraph/drawDates';
import drawHorizontalLines from '../../helpers/linearGraph/drawHorizontalLines';
import getDataIndex from '../../helpers/linearGraph/getDataIndex';
import createChartElement from '../../helpers/linearGraph/createChartElement';
import longestValue from '../../helpers/longestValue';
import longestSign from '../../helpers/longestSign';

class MultiLinear {
  constructor(svg, translate, config) {
    this._chart = createChartElement(svg, translate);
    this._config = config;
    this._tooltip = new Tooltip(this._chart, this._config);
  }

  draw(data, sizes) {
    this._data = this._deleteEmptyDatasets(data);
    this._colors = this._getColors(this._config.colors , this._data.length);
    this._mergedData = [].concat.apply([], this._data.map(dItem => dItem.data));
    this._params = this._getChartParams(sizes, this._config, this._mergedData);
    this._scales = this._makeScales(this._mergedData, this._params, this._config);
    this._axes = this._makeAxes(this._config.axes, this._scales);
    this._axisLayer = drawAxes(this._chart, this._axes, this._params, this._config);

    this._drawLines(this._data, this._chart, this._colors, this._scales);
    drawDates(this._mergedData, this._axisLayer, this._params, this._config);
    drawTitle(this._axisLayer, this._params.paddings, this._config);
    drawHorizontalLines(this._axisLayer, this._scales.y, this._params);
  }

  remove() {
    if (this._tooltip.tooltipArea) {
      this._tooltip.remove();
    }
  }

  connectTooltip() {
    const { paddings } = this._params;
    const { formattedValue } = this._axes.y;

    this._tooltip.draw(this._colors, paddings, this._params);

    this._tooltip.tooltipArea.
      on('mouseover', () => {
        this._tooltip.show();
      }).
      on('mouseout', () => {
        this._tooltip.hide();
      }).
      on('mousemove', () => {
        const dIndex = getDataIndex(this._data[0].data, this._tooltip.tooltipArea, this._scales.x);
        const dItems = getDataItems(this._data, dIndex, this._colors);

        const translateX = this._scales.x(dItems[0]['created_at']);
        const focusesData = getFocusesData(dItems, this._scales, translateX);
        const tableItems = getTableItems(dItems, formattedValue);

        this._tooltip.update(dItems[0], focusesData, tableItems, translateX);
      });

    function getDataItems(data, dIndex, colors) {
      return data.map((chartData, i) => ({
        created_at: chartData.data[dIndex]['created_at'],
        value: chartData.data[dIndex]['value'],
        title: chartData.title,
        color: colors[i]
      }));
    }

    function getFocusesData(dItems, scales, translateX) {
      return dItems.map(dItem => ({
        color: dItem.color,
        translate: [
          translateX,
          scales.y(dItem['value'])
        ]
      }));
    }

    function getTableItems(dItems, formattedValue) {
      return dItems.map(dItem => ({
        title: dItem.title,
        text: formattedValue ? formattedValue(dItem['value']) : dItem['value'],
        color: dItem.color
      }));
    }
  }

  _deleteEmptyDatasets(data) {
    return data.filter(
      dataset =>  dataset.data.constructor === Array && dataset.data.length > 0
    )
  }

  _getColors(colors, dataLength) {
    const colorsLength = colors.length;

    if (colorsLength > dataLength) {
      colors = colors.slice(0, dataLength);
    } else if (colorsLength < dataLength) {
      for (let i = 0; i < dataLength - colorsLength; i++) {
        colors.push(
          "#" + Math.random().toString(16).slice(2, 8)
        )
      }
    }

    return colors;
  }

  _getChartParams(sizes, config, mergedData) {
    const valueLength = longestValue(mergedData, 'value', config.axes.y.domain);
    const formatLength = longestSign(config.axes.y.format);

    return getParams(sizes, config, [valueLength + formatLength]);
  }

  _makeScales(mergedData, params, config) {
    const scale = new Scale(mergedData, params);

    return {
      x: scale.x(),
      y: scale.y('value', config.axes.y.domain, config.fontSize)
    };
  }

  _makeAxes(axesConfig, scales) {
    const axis = new Axis({
      x: axesConfig.x.format,
      y: axesConfig.y.format
    });

    return {
      x: axis.x(scales.x),
      y: axis.y(scales.y)
    };
  }

  _drawLines(data, chart, colors, scales) {
    const lineGenerator = makeLineGenerators(
      scales,
      {x: 'created_at', y: 'value'}
    );

    data.forEach(
      (dItem, i) => drawLine(chart, dItem.data, lineGenerator, colors[i])
    );
  }
}

export default MultiLinear;
