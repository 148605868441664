import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    switch (this.data.get('action')) {
      case 'show':
        this.oldCodeShow();
        break;
    }
  }

  oldCodeShow() {
    if ($('.header.review_error').length !== 0) {
      $('#first-ten-events-opener').on('click', function (event) {
        $("table.records > tbody > .table__row").slice(0, 10).each(function () {
          window.open($(this).data('url'), '_blank');
        });

        event.preventDefault();
      });
    }

    $('.button.action').on({
      'ajax:beforeSend': function () {
        getActionButtons(this).addClass('blinked');
      },

      'ajax:success': function () {
        getActionButtons(this).prop('disabled', true);
      },

      'ajax:error': function () {
        $(this).addClass('error');
      },

      'ajax:complete': function () {
        getActionButtons(this).addClass('green').removeClass('blinked');
      }
    });
  }

  getActionButtons(selector) {
    return $(selector).parent().children('.button.action');
  }
}
