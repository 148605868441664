import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    switch (this.data.get('action')) {
      case 'index':
        this.oldCodeIndex();
        break;
      case 'show':
        this.oldCodeShow();
        break;
    }
  }

  oldCodeIndex() {
    $('.status-filter input[name=status]').change(function() {
      this.form.submit();
    });

    $('.help-requests').on('ajax:success', '.js-in-progress, .js-close', function(event){
      var [data, status, xhr] = event.detail;
      var $row = $(event.target).closest('tbody');
      $('.flashes').html(data.flash);
      $row.html(data.html)
    });
  }

  oldCodeShow() {
    var json = JSON.parse($('#dirty-json').text());

    $('#clean-json').text(JSON.stringify(json, undefined, 2));
  }
}
