import * as d3 from 'd3';
import localeRu from '../../helpers/locales/ru';

/** Class represents an axis. */
class Axis {
  /**
   * Constructor.
   *
   * @constructor
   * @param {Object} formats - Axis formats.
   */
  constructor(formats) {
    d3.timeFormatDefaultLocale(localeRu);

    this._formats = formats;
    this._formatsArr = this._getFormatsArr(this._formats.y);

    this._ticksCount = 2; //количество меток на графике
    this._tickSizeInner = 4, //длина штриха, px
    this._decimalPlaces = 2; //округление значений, знаков после запятой
    this._roundUp = Math.pow(10, this._decimalPlaces);
  }

  /**
   * Creates an X axis.
   *
   * @param {function} scale - The scale function.
   * @return {function} The axis function.
   */
  x(scale) {
    const axisX = d3.axisBottom(scale)
      .tickFormat(d => d3.timeFormat(this._formats.x)(d))
      .tickSizeInner(this._tickSizeInner);

    return axisX;
  }

  /**
   * Creates an Y axis.
   *
   * @param {function} scale - The scale function.
   * @param {string} position - Indicates the location of the axis ('left' or 'right').
   * @return {function} The axis function.
   */
  y(scale, position = 'left') {
    const axisY = (position == 'left')
      ? d3.axisLeft(scale)
      : d3.axisRight(scale);

    axisY
      .ticks(this._ticksCount)
      .tickSizeInner(this._tickSizeInner);

    const ticks = axisY.scale().ticks();
    const axisFormat = this._getAxisFormat(ticks, this._formatsArr);

    if (this._formatsArr) {
      axisY.formattedValue = this._getFormattedValue(axisFormat);
      axisY.tickFormat(d => axisY.formattedValue(d));
    }

    return axisY;
  }

  /**
   * Transform the object of axis formats to array.
   *
   * @private
   * @param {Object} formats - Axis formats.
   * @return {Array} Array of axis formats.
   */
  _getFormatsArr(formats) {
    if (!formats || formats.constructor != Object || d3.keys(formats).length == 0) return false;

    const signsArr = [];
    for (const k in formats) {
      signsArr.push({
        name: k,
        value: formats[k]
      });
    }

    return signsArr.sort((a, b) => a.value - b.value);
  }

  /**
   * Determines the appropriate axis format.
   *
   * @private
   * @param {Array} ticks - Array of ticks of the axis.
   * @param {Array} formatsArr - Array of axis formats.
   * @return {Object} The format of the axis.
   */
  _getAxisFormat(ticks, formatsArr) {
    const maxTickValue = d3.max(ticks);
    const index = getIndex(maxTickValue, formatsArr);

    function getIndex(value, formatsArr) {
      let index = 0;

      for (let i = 0; i < formatsArr.length; i++)
        if (value >= formatsArr[i].value) index = i;

      return index;
    }

    return formatsArr[index];
  }

  /**
   * Creates a formatting function.
   *
   * @private
   * @param {} axisFormat - The format of the axis.
   * @return {function} Formatting function.
   */
  _getFormattedValue(axisFormat) {
    const self = this;

    return function(value) {
      const tickValue = Math.round(value * self._roundUp / axisFormat.value) / self._roundUp;

      return `${tickValue} ${axisFormat.name}`;
    };
  }
}

export default Axis;
