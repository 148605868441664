/** @module helpers/capitalizeFirstLetter */

/**
 * Converts the first letter to uppercase.
 *
 * @param {string} str - Any string.
 * @return {string}
 */
export default (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
}