import Scratch from './Scratch';
import Table from './Table';
import Focuses from './Focuses';

class Tooltip {
  constructor(parent, config) {
    this._parent = parent;
    this._config = config;
    this._scratch = new Scratch(this._parent);
    this._focuses = new Focuses(this._parent);
    this._table = new Table();
  }

  get tooltipArea() {
    return this._tooltipArea;
  }

  draw(colors, paddings, chartParams) {
    this._tooltipArea = this._drawTooltipArea(this._parent, chartParams);
    this._scratch.draw(paddings, this._config);
    this._focuses.draw(colors);
    this._table.draw();
  }

  remove() {
    this._scratch.remove();
    this._focuses.remove();
    this._table.remove();
    this._tooltipArea.remove();
  }

  show() {
    this._scratch.show();
    this._focuses.show();
    this._table.show();
  }

  hide() {
    this._scratch.hide();
    this._focuses.hide();
    this._table.hide();
  }

  update(dItem, focusesData, tableItems, translateX) {
    this._scratch.translate(translateX);
    this._focuses.translate(focusesData);
    this._table.update(dItem, tableItems);
    this._table.translate();
  }

  /**
   * Draws area to handle tooltip's events.
   */
  _drawTooltipArea(parent, chartParams) {
    const {paddings, height, width} = chartParams;

    const rect = parent.append('rect')
      .attr('class', 'tooltip-rect')
      .attr('x', paddings / 2)
      .attr('y', paddings / 2)
      .attr('width', width)
      .attr('height', height);

    return rect;
  }
}

export default Tooltip;
