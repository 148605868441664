import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    switch (this.data.get('action')) {
      case 'index':
        this.oldCodeIndex();
        break;
      case 'show':
        this.oldCodeShow();
        break;
    }
  }

  oldCodeIndex() {
    count_down();

    $('.event_id_checkbox').on('change', function () {
      var checked_array = [];

      $("input:checkbox:checked.event_id_checkbox").each(function(){
        checked_array.push($(this).data('event_id'));
      });

      var uniq_values = checked_array.filter((v, i, a) => a.indexOf(v) === i);
      $('#events_ids_for_fraud').attr('value', uniq_values.join(','))
    });

    $('.screenshots-grid').on('click', '.cross', function () {
      $(this).parent().find('[type=checkbox]').click();
    });

    function count_down() {
      var time_finish = $('.time-finish').data('time-finish');
      var countDownDate = new Date(time_finish).getTime();

      var time_x = setInterval(function() {
        var now = new Date().getTime();
        var distance = countDownDate - now;
        var seconds = Math.floor(distance / 1000);
        var css_class = 'green-text';

        if (seconds < 60) {
          css_class = 'red-text';
        } else if( seconds < 120) {
          css_class = 'yellow-text';
        }

        var html = '<span class="' + css_class + '">' + seconds + ' секунд</span>';

        $('.time-finish').html(html);

        if (distance < 1000) {
          clearInterval(time_x);

          $('.submit-button').prop('disabled', true);
          $('.screenshot .dropdown').addClass('disabled');

          $('.time-finish').html(
            '<span class="red-text">Время на модерацию истекло, обновите пожалуйста странницу</span>'
          );
        }
      }, 1000);
    }
  }

  oldCodeShow() {

  }
}
