$(function () {

  var visitDataUrl = function (event) {
    if (event.type == "click" ||
      event.keyCode == keycodes.space ||
      event.keyCode == keycodes.enter) {

      if (!event.target.href) {
        var $target = $(event.target);

        if($target.closest("td").data('noLink')) {
          return
        }

        var row = $target.closest("tr");
        var cell = $target.closest("td");
        var url = cell.data("url") || row.data("url");
        var target = cell.data("target") || row.data("target");
        if(typeof url !== 'undefined') {
          if(row.data("inTab")) {
            window.open(url);
          } else if (target) {
            window.open(url, target)
          } else if (event.metaKey || event.ctrlKey) {
            window.open(url, '_blank')
          } else {
            window.location = url;
          }
        }
      }
    }
  };

  $("body").on("click", ".table__row, .js-clickable-row", function (event) {
    var sel = getSelection().toString();
    if(!sel){
      visitDataUrl(event);
    }
  });
});
