import Linear from '../modules/charts/Linear';
import DoubleLinear from '../modules/charts/DoubleLinear';
import MultiLinear from '../modules/charts/MultiLinear';
import Binary from '../modules/charts/Binary';
import ScreenMessage from '../modules/ScreenMessage';

/** Class represents a manager of the charts. */
class ChartManager {
  constructor() {
    this._screenMessage = new ScreenMessage();
    this._charts = [];
  }

  /**
   * Creates new chart.
   */
  createChart(type, data, translate, config, svg, sizes, connectTooltip) {
    if (data.constructor === Error) {
      return this.drawError(type, data, translate, config, svg, sizes);
    } else {
      this[`_${type}`](data, translate, config, svg, sizes, connectTooltip);
    }
  }

  removeCharts() {
    this._charts.forEach(chart => chart.remove());
    this._charts = [];
  }

  /**
   * Draw screen error.
   */
  drawError(type, error, translate, config, svg, sizes) {
    const screenMessages = config.screenMessages;
    let message = '';

    if (error.message == 'CONNECTION_REFUSED') {
      message = screenMessages.connectionRefused;
    } else if (error.message == 'DATA_IS_EMPTY' && type != 'binary') {
      message = screenMessages.dataIsEmpty;
    }

    this._screenMessage.draw(svg, sizes, translate, message);

    return false;
  }

  /**
   * Draws linear chart.
   */
  _linear(data, translate, config, svg, sizes, connectTooltip) {
    const linear = new Linear(svg, translate, config);
    this._charts.push(linear);

    this._chartParams = linear.draw(data, sizes);

    if (connectTooltip) {
      linear.connectTooltip();
    }
  }

  /**
   * Draw double linear chart.
   */
  _doubleLinear(data, translate, config, svg, sizes, connectTooltip) {
    const doubleLinear = new DoubleLinear(svg, translate, config);
    this._charts.push(doubleLinear);

    doubleLinear.draw(data, sizes);

    if (connectTooltip) {
      doubleLinear.connectTooltip();
    }
  }

  /**
   * Draw multi linear chart.
   */
  _multiLinear(data, translate, config, svg, sizes, connectTooltip) {
    const multiLinear = new MultiLinear(svg, translate, config);
    this._charts.push(multiLinear);

    multiLinear.draw(data, sizes);

    if (connectTooltip) {
      multiLinear.connectTooltip();
    }
  }

  /**
   * Draws binary chart.
   */
  _binary(data, translate, config, svg, sizes) {
    const binary = new Binary(svg, translate, config);
    this._charts.push( binary );

    binary.draw(data, sizes, this._chartParams);
  }
}

export default ChartManager;
