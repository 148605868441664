/** @module helpers/offsetTimezone */

/**
 * Calculates timezone offset, ms.
 *
 * @param {string} timezoneOffset - Timezone offset, example: "+05:00".
 * @return {number} Timezone offset, ms.
 */
function parseTimezone(timezoneOffset) {
  const hourOffset = parseInt(timezoneOffset) || 0;

  return hourOffset * 60 * 60 * 1000;
}

/**
 * Offsets timezone of data.
 *
 * @param {Array} data - The array of data.
 * @param {Object} timezone - The object with a name and offset of time zone.
 * @return {Array} The array of data with offset time zone.
 */
export default (data, timezone) => {
  if (!data) return false;

  const timezoneOffset = parseTimezone(timezone.offset);
  const offsetTimeData = data.map((d) => {
    d['created_at'] = new Date(d['created_at'].getTime() + timezoneOffset);

    return d;
  });

  return offsetTimeData;
};