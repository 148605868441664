/** @module helpers/linearGraph/getParams */

/**
 * Combines the basic geometric characteristics of the chart.
 */
export default (sizes, config, signLength) => {
  const width = sizes.width;
  const height = sizes.height;
  const paddings = config.padding;

  const distance = {
    x: config.fontSize.graph,
    y1: signLength[0] * config.fontSize.graph * 0.8,
    y2: (signLength[1] || 0) * config.fontSize.graph * 0.8
  };

  return {width, height, paddings, distance};
};
