import { Controller } from 'stimulus';
import Choices from "choices.js";

export default class extends Controller {
  static targets = ['select'];

  connect() {
    this.selectify();
  }

  selectify() {
    if(this.hasSelectTarget) {
      this.selectTargets.forEach((select, _index) => {
        new Choices(select, {});
      });
    }
  }
}
