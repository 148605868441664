function publishFlash(flash) {
  const event = new CustomEvent(
    'update-flash',
    {detail: {html: flash}}
    );

  window.dispatchEvent(event);
}

export {publishFlash};
