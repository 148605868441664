/** @module helpers/linearGraph/drawAxes */

/**
 * Draws axes.
 *
 * @param {Selection} graph - Chart element.
 * @param {Object} axes - Chart axes.
 * @param {Object} params - Chart parameters.
 * @param {Object} config - Configuration object.
 * @return {Selection} Axis layer.
 */
export default (graph, axes, params, config) => {
  const {width, height, paddings, distance} = params;
  const {fontSize} = config;

  const axisLayer = graph.insert('g')
    .attr('class', 'axis-layer');

  if (axes.x) {
    axisLayer.append('g')
      .attr('class', 'axis axis--x')
      .attr('transform', `translate(${[0, height - paddings - distance.x]})`)
      .call(axes.x);
  }

  if (axes.y || axes.y1) {
    axisLayer.append('g')
      .attr('class', 'axis axis--y')
      .attr('transform', `translate(${[distance.y1 + paddings, 0]})`)
      .call(axes.y || axes.y1);
  }

  if (axes.y2) {
    axisLayer.append('g')
      .attr('class', 'axis axis--y')
      .attr('transform', `translate(${[width - distance.y2 - paddings, 0]})`)
      .call(axes.y2);
  }

  //set font size
  axisLayer.selectAll('.tick > text')
    .style('font-size', fontSize.graph);

  return axisLayer;
};
