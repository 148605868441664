/** @module helpers/createSvg */

/**
 * Create new SVG.
 *
 * @param {Selection} elem - parent HTML element.
 * @param {Object} sizes - SVG sizes.
 * @param {string} className - SVG class.
 * @return {Selection} - SVG element.
 */
export default (elem, sizes, className) => {
  const svg = elem.append('svg')
    .attr('class', 'ping-tooltip-graph')
    .attr('width', sizes.width)
    .attr('height', sizes.height);

  if (className)
    svg.classed(className, true);

  return svg;
};