import * as d3 from 'd3';

import createSvg from '../../helpers/createSvg';
import attributesConverter from '../../helpers/attributesConverter';
import mergeConfig from '../../helpers/mergeConfig';
import ChartManager from '../../modules/ChartManager';
import graphSizes from './graphSizes';

/** Class represents a built in chart. */
class BuiltInChart {
  constructor(config) {
    this._config = config;
    this._chartManager = new ChartManager();
  }

  /**
   * Creates a chart for target HTML element.
   */
  createForElement(parent, data) {
    this._parent = parent;

    const paths = Object.keys(data);
    const svg = createSvg(d3.select(parent), parent.getBoundingClientRect(), 'built-in');
    const sizes = graphSizes(parent, paths.length);

    for (let i = 0; i < paths.length; i++) {
      const translate = [0, sizes.height * i];
      const dataItem = data[paths[i]];

      if (dataItem.constructor === Error) {
        this._drawError(dataItem, translate, this._config.common, svg, sizes);
      } else {
        this._drawChart(dataItem, translate, svg, sizes, parent);
      }
    }
  }

  _drawError(dataItem, translate, config, svg, sizes) {
    this._chartManager.createChart(null, dataItem, translate, this._config.common, svg, sizes);
  }

  /**
   * Draws the chart.
   */
  _drawChart(data, translate, svg, sizes, parent) {
    for (const k in data) {
      const graphConfig = mergeConfig.intersection(this._config[k], attributesConverter(parent));
      this._chartManager.createChart(k, data[k], translate, graphConfig, svg, sizes, true);
    }
  }

  /**
   * Removes charts for target HTML element.
   */
  remove() {
    this._chartManager.removeCharts();
    parent = d3.select(this._parent);

    parent.selectAll('svg.ping-tooltip-graph').remove();
    parent.selectAll('.spinner').remove();
    parent.on('mouseenter', null);
  }
}

export default BuiltInChart;
