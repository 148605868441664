(function () {
  $('.separately-submitter').on('click', function () {
    var $this = $(this);
    var form = $('#' + $this.data('target-form'));
    var input = $("<input>")
      .attr("type", "hidden")
      .attr("name", $this.attr('name')).val("save");
    form.append($(input));

    $this
      .prop("disabled", true)
      .attr('value', $this.data('disable-with'));

    form.submit();

    input.remove();
  });
})();
