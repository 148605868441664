class Focuses {
  constructor(parent) {
    this._parent = parent;
  }

  draw(colors) {
    this._focuses = [];

    colors.forEach(color => {
      const focus = this._parent.select('.chart-layer').
        append('g').
        attr('class', 'focus').
        style('display', 'none');

      focus.append('circle').
        attr('fill', color).
        attr('r', 3);

      this._focuses.push({ color, elem: focus });
    });
  }

  remove() {
    this._focuses.forEach(focus => focus.elem.remove());
    this._focuses = [];
  }

  show() {
    this._focuses.forEach(focus => focus.elem.style('display', null));
  }

  hide() {
    this._focuses.forEach(focus => focus.elem.style('display', 'none'));
  }

  translate(focusesData) {
    focusesData.forEach(focusData => {
      const focus = this._focuses.find(
        focus => focus.color === focusData.color
      );

      focus.elem.attr('transform', `translate(${focusData.translate})`);
    });
  }
}

export default Focuses;
