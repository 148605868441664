import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    let controller = this;

    $('.timesheets-table').on('mouseenter', 'td.violation', function(){
      controller.currentToolip = this;
      let enteredTooltip = this;
      setTimeout(function() {
        if(controller.currentToolip === enteredTooltip) {
          controller.openTooltip($(controller.currentToolip));
        }
      }, 500);
    });

    $('.timesheets-table').on('mouseleave', 'td.violation', function(){
      controller.currentToolip = null;
      var $this = $(this);
      var violation_id = $this.data('violation-id');

      setTimeout(function () {
        if($('td.' + violation_id + ":hover").length === 0) {
          var $td = $('td#' + violation_id);

          $td.removeClass('opened_tooltip');
          $('td#' + violation_id).find('.tooltip').hide(1);

          let enteredTooltip = $("td.violation:hover");
          if(enteredTooltip.length > 0) {
            setTimeout(function() {
              console.log(controller.currentToolip === enteredTooltip);
              if(controller.currentToolip === enteredTooltip[0]) {
                controller.openTooltip(enteredTooltip);
              }
            }, 500);
          }
        }
      }, 300);
    });

    $(".tooltip").on("click", ".wrap", function (event) {
      var $target = $(event.target);
      var $wrap = $target.closest(".wrap");
      var url = $wrap.data("url");

      if(typeof url !== 'undefined' && url !== '' && url !== '#') {
        window.open(url);
      }
    });
  }

  openTooltip(element) {
    if($("td.violation.opened_tooltip").length !== 0) {
      return;
    }

    var $this = $(element);
    var violation_id = $this.data('violation-id');
    var $td = $('td#' + violation_id);
    var $tooltip = $td.find('.tooltip');
    let $sticky_wrap = $('.sticky-wrap');

    $td.addClass('opened_tooltip');
    $tooltip.show();

    var td_position = $td.position();
    var bottom_position = $this.height() - $tooltip.outerHeight();
    var right_position = ($tooltip.outerWidth() * -1) - $this.outerWidth();

    var difference_top = td_position.top - $tooltip.height() - bottom_position - $('.sticky-thead').height();
    var difference_bottom = $sticky_wrap.height() - td_position.top - $this.height() - (bottom_position * -1);
    var difference_right = $sticky_wrap.outerWidth() - td_position.left - ($this.outerWidth() * 2) - $tooltip.outerWidth();

    if (difference_bottom < 0 && difference_top > 0) {
      $tooltip.css({ bottom: bottom_position - difference_bottom + 20 });
    } else if (difference_bottom > 0 && difference_top < 0) {
      $tooltip.css({ bottom: bottom_position + difference_top });
    } else {
      $tooltip.css({ bottom: bottom_position });
    }

    if (difference_right < 0) {
      $tooltip.css({ right: $this.width() * 2});
    }else{
      $tooltip.css({ right: right_position });
    }
  }
}
