/** @module modules/TooltipChart/updateSvgSizes */

/**
 * Sets the width and height of the target SVG element.
 */
export default (svg, sizes, chartsNumber) => {
  svg
    .attr('width', sizes.width)
    .attr('height', sizes.height * chartsNumber);
};
