/** Class represents a screen message. */
class ScreenMessage {
  /**
   * Draw screen message.
   */
  draw(svg, sizes, translate, text) {
    const {width, height} = sizes;

    const x = (translate[0] + width) / 2;
    const y = translate[1] + height / 2;
    const fontSize = this._calculateFontSize(width, height, text);

    this.textContainer = svg.append('g')
      .attr('class', 'screen-message');

    this.message = this.textContainer.append('text')
      .attr('x', x)
      .attr('y', y)
      .attr('dy', '0.32em')
      .style('font-size', fontSize)
      .text(text || '');
  }

  /**
   * Remove all screen messages from SVG.
   */
  remove(svg) {
    svg.selectAll('.screen-message').remove();
  }

  /**
   * Calculates the font size of the message.
   */
  _calculateFontSize(width, height, text) {
    const textLength = (text && text.length >= 10)
      ? text.length
      : 10;

    const fontSizeByWidth = width * 1.5 / textLength;
    const fontSizeByHeight = height / 3;

    return Math.min(fontSizeByWidth, fontSizeByHeight);
  }
}

export default ScreenMessage;
