$(document).ready(function () {
  $('.accordion-tabs').each(function (index) {
    $(this).children('li').first().children('a').addClass('is-active').next().addClass('is-open').show();
  });

  $('.accordion-tabs').on('click', 'li > a.tab-link', function (event) {
    event.preventDefault();
    click_on_tab(this);
    set_url_hash(this);
  });

  process_tab_hash();

  $(window).on('hashchange', function() {
    process_tab_hash();
  });

  function process_tab_hash() {
    var url_hash = window.location.hash.substr(1);

    if(url_hash.length !== 0) {
      var tab = $('.accordion-tabs').find("li > a.tab-link[data-tab-hash='" + url_hash + "']");
      if(tab.length !== 0) {
        setTimeout(function() {
          tab.click();
        }, 50);
      }
    }
  }

  function click_on_tab(tab_link) {
    if (!$(tab_link).hasClass('is-active')) {
      var accordionTabs = $(tab_link).closest('.accordion-tabs');
      accordionTabs.find('.is-open').removeClass('is-open').hide().trigger('tab:hidden');

      $(tab_link).next().toggleClass('is-open').toggle();
      accordionTabs.find('.is-active').removeClass('is-active');
      $(tab_link).addClass('is-active');
      $(tab_link).next()[0].dispatchEvent(new Event('tab:activated'));
    }
  }

  function set_url_hash(tab) {
    var tab_hash = $(tab).data('tab-hash');
    var url_hash = window.location.hash.substr(1);

    if (url_hash !== tab_hash) {
      if(history.pushState) {
        history.pushState(null, null, '#' + tab_hash );
      } else {
        location.hash = '#' + tab_hash ;
      }
    }
  }
});
